let _timePickerEvents = () => {
  $('input[type=\'text\'][data-behavior=\'date\']').each(function () {
    $(this).flatpickr({
      enableTime: true,
      altInput: true,
      minDate: 'today',
      altFormat: 'F j, Y at h:i K',
      dateFormat: 'Z',
      defaultHour: new Date().getHours(),
      defaultMinute: new Date().getMinutes(),
      minuteIncrement: 1,
    });
  });

  $('input[type=\'text\'][data-behavior=\'date-no-alt\']').each(function () {
    $(this).flatpickr({
      enableTime: true,
      altInput: false,
      minDate: 'today',
      altFormat: 'F j, Y at h:i K',
      dateFormat: 'Z',
      defaultHour: new Date().getHours(),
      defaultMinute: new Date().getMinutes(),
      minuteIncrement: 1,
    });
  });

  $('input[type=\'text\'][data-behavior=\'date-no-min\']').each(function () {
    $(this).flatpickr({
      enableTime: true,
      altInput: true,
      altFormat: 'F j, Y at h:i K',
      dateFormat: 'Z',
      defaultHour: new Date().getHours(),
      defaultMinute: new Date().getMinutes(),
      minuteIncrement: 1,
    });
  });

  $('input[type=\'text\'][data-behavior=\'time\']').each(function () {
    $(this).flatpickr({
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true,
    });
  });

  $('[data-convert-localtz-time]').each(function () {
    let date = new Date($(this).data('convert-localtz-time'));
    let mins = date.getMinutes();
    let hours = date.getHours();
    let period = hours >= 12 ? 'PM' : 'AM';
    let hours_12 = hours % 12;
    hours_12 = hours_12 ? hours_12 : 12;

    if (hours_12 < 10) {
      hours_12 = '0' + hours_12;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    $(this).html(hours_12 + ':' + mins + ' ' + period);
  });

  $('[data-convert-localtz-date]').each(function () {
    let date = new Date($(this).data('convert-localtz-date'));
    let day = date.getDate();
    let dow = date.getDay();
    let month = date.getMonth();

    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    $(this).html(
      '' +
        days[dow].substring(0, 3) +
        ', ' +
        day +
        ' ' +
        months[month].substring(0, 3)
    );
  });
};
document.addEventListener('cable-ready:after-morph', _timePickerEvents);
document.addEventListener('turbo:load', _timePickerEvents);
