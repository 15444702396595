import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production' && window._mt) {
  var mixpanel = require('mixpanel-browser');
  mixpanel.init(window._mt);
}

document.addEventListener('turbo:load', function () {
  if (process.env.NODE_ENV === 'production' && window._sentry_client_dsn) {
    Sentry.setUser({
      id: window._mt_userid,
      fullName: window._mt_username,
      email: window._mt_useremail,
    });

    Sentry.init({
      dsn: window._sentry_client_dsn
    });
    
    const feedback = Sentry.feedbackIntegration({
      useSentryUser: {
        name: "fullName",
        email: "email"
      },
      colorScheme: "light",
      triggerLabel: "",
      submitButtonLabel: "Send Feedback",
      formTitle: "Send Feedback",
      showBranding: false,
      messagePlaceholder: "Share your feedback or report a bug",
      successMessageText: "Thanks for your feedback! We'll use it to improve the app.",
    });

    // const widget = feedback.createWidget();
    // widget.appendToDom();
  }
});
