// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '@hotwired/turbo-rails';
import '../lib/rails_common';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import '../stylesheets/public_pages';
import '../lib/bs_custom.js';
require('flatpickr/dist/flatpickr.css');
require('jstz');
import '../lib/vendor';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import '../lib/telephone_input.js';
import '../lib/timepickr-and-timezone.js';
import 'controllers';
import 'cocoon-js';

document.addEventListener('turbo:load', () => {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
  });
});
